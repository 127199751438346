import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Layout from '../components/layout/layout';
import Container from '../components/common/container';

export default function Privacy({ initialData }) {
  const [activeSection, setActiveSection] = useState('');
  const [showBackToTop, setShowBackToTop] = useState(false);

  // Handle initial hash and hash changes
  useEffect(() => {
    const scrollToHash = () => {
      const hash = window.location.hash.slice(1); // Remove the # symbol
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          const yOffset = -80;
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
          setActiveSection(hash);
        }
      }
    };

    // Handle initial hash on page load
    scrollToHash();

    // Listen for hash changes
    window.addEventListener('hashchange', scrollToHash);
    return () => window.removeEventListener('hashchange', scrollToHash);
  }, []);

  // Track scroll position for navigation highlighting
  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 400);

      const sections = document.querySelectorAll('section[id]');
      sections.forEach(section => {
        const sectionTop = section.offsetTop - 100;
        if (window.scrollY >= sectionTop) {
          const id = section.getAttribute('id');
          setActiveSection(id);
          // Update URL hash without scrolling
          const newUrl = `${window.location.pathname}${id ? '#' + id : ''}`;
          window.history.replaceState(null, '', newUrl);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navigationSections = [
    { id: 'overview', label: 'Overview' },
    { id: 'collection', label: 'Information Collection' },
    { id: 'processing', label: 'Information Processing' },
    { id: 'sharing', label: 'Information Sharing' },
    { id: 'thirdparty', label: 'Third-Party Websites' },
    { id: 'cookies', label: 'Cookies & Tracking' },
    { id: 'retention', label: 'Data Retention' },
    { id: 'security', label: 'Information Security' },
    { id: 'minors', label: 'Information from Minors' },
    { id: 'rights', label: 'Your Privacy Rights' },
    { id: 'california', label: 'California Rights' },
    { id: 'updates', label: 'Policy Updates' },
    { id: 'contact', label: 'Contact Us' },
    { id: 'datachoices', label: 'Your Data Choices' }
  ];

  const smoothScrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      // Smooth scroll to element
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      
      // Update URL without causing another scroll (using replaceState)
      const newUrl = `${window.location.pathname}#${elementId}`;
      window.history.replaceState(null, '', newUrl);
      
      // Update active section
      setActiveSection(elementId);
    }
  };

  return (
    <Layout initialData={initialData}>
      {/* Hero Section */}
      <div className="from-primary/5 to-transparent">
        <Container>
          <motion.div 
            className="max-w-3xl mx-auto text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-primary to-primary-hover bg-clip-text text-transparent">
              Privacy Notice
            </h1>
            <p className="text-gray-600 text-lg leading-relaxed">
              This privacy notice for Wymbee Technologies Limited ('Company', 'we', 'us', or 'our') describes how we collect, store, use, and share your information when you use our services.
            </p>
          </motion.div>
        </Container>
      </div>

      <Container className="relative">
        <div className="flex flex-col lg:flex-row gap-12 py-12">
          {/* Side Navigation */}
          <div className="lg:w-1/4">
            <div className="sticky top-24 space-y-6 hidden lg:block">
              <nav className="space-y-1">
                {navigationSections.map((section) => (
                  <button
                    key={section.id}
                    onClick={() => smoothScrollTo(section.id)}
                    className={`w-full text-left px-4 py-2 text-sm rounded-lg transition-colors duration-200 hover:bg-primary/5 
                      ${activeSection === section.id ? 'text-primary font-medium bg-primary/5' : 'text-gray-600'}`}
                  >
                    {section.label}
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:w-3/4">
            <div className="prose max-w-none">
              {/* Overview Section */}
              <motion.section
                id="overview"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Overview</h2>
                <p className="text-gray-600 mb-6">
                  This privacy notice applies when you:
                </p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                  <li>Visit our website at wymbee.com</li>
                  <li>Download and use our mobile application (WymBee)</li>
                  <li>Engage with us through sales, marketing, or events</li>
                </ul>
                <div className="mt-8 p-4 bg-primary/5 rounded-lg">
                  <p className="text-gray-700">
                    <strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@wymbee.com.
                  </p>
                </div>
              </motion.section>
              <motion.section
                id="collection"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">What Information Do We Collect?</h2>
                
                <div className="space-y-8">
                  <div>
                    <h3 className="text-xl font-semibold text-primary mb-4">Personal Information You Disclose</h3>
                    <div className="bg-gray-50 p-6 rounded-lg">
                      <p className="italic text-gray-600 mb-4">In Short: We collect personal information that you provide to us.</p>
                      <p className="text-gray-600 mb-4">
                        We collect personal information that you voluntarily provide when you:
                      </p>
                      <ul className="list-disc pl-6 space-y-2 text-gray-600">
                        <li>Register on our Services</li>
                        <li>Express interest in our products and Services</li>
                        <li>Participate in activities on the Services</li>
                        <li>Contact us</li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <h4 className="text-lg font-medium mb-3">Personal Information Provided by You</h4>
                    <p className="text-gray-600 mb-4">The personal information we collect may include:</p>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      <div className="bg-white p-3 rounded border border-gray-100">Names</div>
                      <div className="bg-white p-3 rounded border border-gray-100">Phone numbers</div>
                      <div className="bg-white p-3 rounded border border-gray-100">Email addresses</div>
                      <div className="bg-white p-3 rounded border border-gray-100">Usernames</div>
                      <div className="bg-white p-3 rounded border border-gray-100">Passwords</div>
                      <div className="bg-white p-3 rounded border border-gray-100">Billing addresses</div>
                    </div>
                  </div>

                  <div>
                    <h4 className="text-lg font-medium mb-3">Application Data</h4>
                    <div className="space-y-4">
                      <div className="p-4 bg-white rounded-lg border border-gray-100">
                        <h5 className="font-medium text-primary mb-2">Geolocation Information</h5>
                        <p className="text-gray-600">
                          We may request access to track location-based information from your mobile device. You can manage this in your device's settings.
                        </p>
                      </div>
                      <div className="p-4 bg-white rounded-lg border border-gray-100">
                        <h5 className="font-medium text-primary mb-2">Mobile Device Access</h5>
                        <p className="text-gray-600">
                          We may request access to features like camera, microphone, storage, contacts, and other features.
                        </p>
                      </div>
                      <div className="p-4 bg-white rounded-lg border border-gray-100">
                        <h5 className="font-medium text-primary mb-2">Push Notifications</h5>
                        <p className="text-gray-600">
                          We may request to send push notifications regarding your account or app features.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Information Processing Section */}
              <motion.section
                id="processing"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">How Do We Process Your Information?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law.
                  </p>
                </div>

                <div className="grid gap-6">
                  {[
                    {
                      title: "Account Management",
                      description: "To facilitate account creation and authentication and otherwise manage user accounts"
                    },
                    {
                      title: "Service Delivery",
                      description: "To deliver and facilitate delivery of services to the user"
                    },
                    {
                      title: "Communications",
                      description: "To respond to inquiries, send administrative information, and maintain contact"
                    },
                  ].map((item, index) => (
                    <div key={index} className="p-6 bg-white rounded-lg border border-gray-100 hover:border-primary/20 transition-colors duration-200">
                      <h3 className="font-semibold text-lg mb-2">{item.title}</h3>
                      <p className="text-gray-600">{item.description}</p>
                    </div>
                  ))}
                </div>
              </motion.section>
              {/* Information Sharing Section */}
              <motion.section
                id="sharing"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">When & With Whom Do We Share Your Personal Information?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: We may share information in specific situations with third parties under proper safeguards.
                  </p>
                </div>

                <div className="space-y-6">
                  {/* Vendors Section */}
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-lg mb-3 text-primary">Vendors and Service Providers</h3>
                    <p className="text-gray-600 mb-4">
                      We may share your data with third-party vendors, service providers, contractors, or agents who perform services for us and need access to such information to do their work. We have contracts in place to help safeguard your personal information.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <h4 className="font-medium mb-2">Advertising & Marketing</h4>
                        <p className="text-sm text-gray-600">Google AdSense and Bing Ads</p>
                      </div>
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <h4 className="font-medium mb-2">Cloud Services</h4>
                        <p className="text-sm text-gray-600">Google Cloud Platform</p>
                      </div>
                    </div>
                  </div>

                  {/* Other Sharing Scenarios */}
                  <div className="grid gap-6">
                    {[
                      {
                        title: "Business Transfers",
                        description: "We may share or transfer your information during negotiations of any merger, sale of company assets, financing, or acquisition."
                      },
                      {
                        title: "Google Maps Platform APIs",
                        description: "When using Google Maps features, we may share certain information. You can review Google's Privacy Policy for more details."
                      },
                      {
                        title: "Business Partners",
                        description: "We may share information with our business partners to offer you certain products, services, or promotions."
                      }
                    ].map((item, index) => (
                      <div key={index} className="p-6 bg-white rounded-lg border border-gray-100 hover:border-primary/20 transition-colors duration-200">
                        <h3 className="font-semibold text-lg mb-2">{item.title}</h3>
                        <p className="text-gray-600">{item.description}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </motion.section>

              {/* Third-Party Websites Section */}
              <motion.section
                id="thirdparty"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">What Is Our Stance On Third-Party Websites?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services.
                  </p>
                </div>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-4">
                    Our Services may link to third-party websites, online services, or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice.
                  </p>
                  <div className="flex items-center p-4 bg-primary/5 rounded-lg mt-4">
                    <svg className="w-6 h-6 text-primary flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p className="ml-3 text-sm text-gray-600">
                      We recommend reviewing the privacy policies of any third-party sites you visit.
                    </p>
                  </div>
                </div>
              </motion.section>

              {/* Cookies Section */}
              <motion.section
                id="cookies"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Do We Use Cookies And Other Tracking Technologies?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: We may use cookies and similar tracking technologies to collect and store your information.
                  </p>
                </div>
                <div className="grid gap-6">
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <p className="text-gray-600">
                      We use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. You can refuse certain cookies through your browser settings.
                    </p>
                  </div>
                </div>
              </motion.section>

              {/* Data Retention Section */}
              <motion.section
                id="retention"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">How Long Do We Keep Your Information?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                  </p>
                </div>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <ul className="space-y-4 text-gray-600">
                    <li className="flex items-start">
                      <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                      <p className="ml-4">We will only keep your personal information for as long as necessary for the purposes set out in this notice.</p>
                    </li>
                    <li className="flex items-start">
                      <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                      <p className="ml-4">No purpose will require keeping your personal information for longer than twelve (12) months past account termination.</p>
                    </li>
                  </ul>
                </div>
              </motion.section>

              {/* Security Section */}
              <motion.section
                id="security"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">How Do We Keep Your Information Safe?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: We aim to protect your personal information through a system of organizational and technical security measures.
                  </p>
                </div>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    We have implemented appropriate technical and organizational security measures to protect your personal information. However, despite our safeguards, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure.
                  </p>
                  <div className="p-4 bg-yellow-50 rounded-lg border border-yellow-100">
                    <p className="text-sm text-yellow-800">
                      You should only access the Services within a secure environment. While we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk.
                    </p>
                  </div>
                </div>
              </motion.section>

              {/* Minors Section */}
              <motion.section
                id="minors"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Do We Collect Information From Minors?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: We do not knowingly collect data from or market to children under 18 years of age.
                  </p>
                </div>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-4">
                    We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services.
                  </p>
                  <div className="flex items-center p-4 bg-primary/5 rounded-lg mt-4">
                    <svg className="w-6 h-6 text-primary flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <p className="ml-3 text-sm text-gray-600">
                      If you become aware of any data we may have collected from children under age 18, please contact us at info@wymbee.com
                    </p>
                  </div>
                </div>
              </motion.section>
              {/* Privacy Rights Section */}
              <motion.section
                id="rights"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">What Are Your Privacy Rights?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: You may review, change, or terminate your account at any time.
                  </p>
                </div>

                <div className="space-y-6">
                  {/* Account Rights */}
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-lg mb-4 text-primary">Account Information</h3>
                    <p className="text-gray-600 mb-4">If you would like to:</p>
                    <ul className="space-y-3 text-gray-600 mb-6">
                      <li className="flex items-start">
                        <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                        <span className="ml-4">Review or change information in your account</span>
                      </li>
                      <li className="flex items-start">
                        <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                        <span className="ml-4">Terminate your account</span>
                      </li>
                      <li className="flex items-start">
                        <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                        <span className="ml-4">Request data deletion</span>
                      </li>
                    </ul>
                    <p className="text-gray-600">
                      You can:
                      <ul className="list-disc pl-6 mt-2 space-y-2">
                        <li>Log in to your account settings and update your user account</li>
                        <li>Contact us using the contact information provided</li>
                      </ul>
                    </p>
                  </div>

                  {/* Withdrawing Consent */}
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-lg mb-4 text-primary">Withdrawing Your Consent</h3>
                    <p className="text-gray-600">
                      If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note that this will not affect the lawfulness of the processing before its withdrawal.
                    </p>
                  </div>

                  {/* European Rights */}
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-lg mb-4 text-primary">Additional Rights for EEA and UK Residents</h3>
                    <p className="text-gray-600 mb-4">
                      If you are located in the EEA or UK and believe we are unlawfully processing your personal information, you have the right to complain to your local data protection supervisory authority.
                    </p>
                    <div className="flex items-center p-4 bg-primary/5 rounded-lg">
                      <svg className="w-6 h-6 text-primary flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <p className="ml-3 text-sm text-gray-600">
                        For EEA residents: Visit the European Commission's website<br/>
                        For Swiss residents: Contact the Swiss data protection authorities
                      </p>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* California Rights Section */}
              <motion.section
                id="california"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Do California Residents Have Specific Privacy Rights?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: Yes, California residents have specific rights regarding access to their personal information.
                  </p>
                </div>

                <div className="space-y-6">
                  {/* Shine the Light Law */}
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-lg mb-4 text-primary">California Civil Code Section 1798.83</h3>
                    <p className="text-gray-600">
                      Also known as the "Shine The Light" law, this permits users who are California residents to request and obtain from us once a year, free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes.
                    </p>
                  </div>

                  {/* CCPA Rights */}
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-lg mb-4 text-primary">CCPA Privacy Notice</h3>
                    <div className="space-y-4">
                      <p className="text-gray-600">Under the CCPA, California residents have the following rights:</p>
                      <ul className="space-y-3 text-gray-600">
                        <li className="flex items-start">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Right to know about the personal information we collect and share</span>
                        </li>
                        <li className="flex items-start">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Right to delete the personal information we have about you</span>
                        </li>
                        <li className="flex items-start">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Right to opt-out of the sale of your personal information</span>
                        </li>
                        <li className="flex items-start">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Right to non-discrimination for exercising your CCPA rights</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Updates Section */}
              <motion.section
                id="updates"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Do We Make Updates To This Notice?</h2>
                <div className="bg-gray-50 p-6 rounded-lg mb-8">
                  <p className="italic text-gray-600">
                    In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
                  </p>
                </div>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600">
                    We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification.
                  </p>
                </div>
              </motion.section>

              {/* Contact Section */}
              <motion.section
                id="contact"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">How Can You Contact Us About This Notice?</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    If you have questions or comments about this notice, you may contact us by:
                  </p>
                  <div className="space-y-4">
                    <div className="flex items-center p-4 bg-primary/5 rounded-lg">
                      <svg className="w-6 h-6 text-primary flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      <p className="ml-3 text-gray-600">
                        Email: info@wymbee.com
                      </p>
                    </div>
                  </div>
                </div>
              </motion.section>
              
              {/* Privacy Request Form Section */}
              <motion.section
                id="privacyrequest"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Privacy Request</h2>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <p className="text-gray-600 mb-6">
                    Use this form to make a privacy request regarding your data. Before making a request to have your data deleted, please make sure you've exited every community you're a part of and deleted all your businesses.
                  </p>
                  
                  <form className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Your Name
                      </label>
                      <input
                        type="text"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                        placeholder="Enter your full name"
                        required
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                        placeholder="Your phone number"
                        required
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                        placeholder="Your email address"
                        required
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Request Details
                      </label>
                      <textarea
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary h-32"
                        placeholder="Please provide details about your request"
                        required
                      />
                    </div>
                    
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="confirm"
                        className="rounded border-gray-300 text-primary focus:ring-primary"
                        required
                      />
                      <label htmlFor="confirm" className="ml-2 text-sm text-gray-600">
                        I confirm my information is accurate
                      </label>
                    </div>
                    
                    <button
                      type="submit"
                      className="w-full bg-primary text-white py-3 rounded-lg hover:bg-primary-hover transition-colors duration-200"
                    >
                      Submit Privacy Request
                    </button>
                  </form>
                </div>
              </motion.section>
              
              {/* Last Updated Section */}
              <motion.div 
                className="text-center border-t border-gray-200 pt-8"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
              >
                <p className="text-gray-500">
                  Last updated: {initialData?.lastUpdated || 'November 27, 2024'}
                </p>
                <p className="text-gray-500 mt-2">
                  If you have any questions about our Privacy Policy, please{' '}
                  <a href="/help" className="text-primary hover:text-primary-hover underline">
                    contact us
                  </a>.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </Container>

      {/* Back to Top Button */}
      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: showBackToTop ? 1 : 0 }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-24 right-6 bg-primary text-white p-3 rounded-full shadow-lg hover:bg-primary-hover transition-colors duration-200"
        aria-label="Back to top"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      </motion.button>
    </Layout>
  );
}